import React, { useState, useEffect } from "react";
import "./stats.scss";
import environment from "../../utils/Enviroment";
import { getStackContract } from "../../utils/contractHelpers";
import useWeb3 from "../../hooks/useWeb3";
import axios from "axios";
import { GetBalance } from "../../hooks/getBalance";
import ProgressBar from 'react-bootstrap/ProgressBar';
const Stats = ({StakeAmountLimit, StakeAggregate,  aggregateTotalStaked, stakeamountlimit, indexwaits, indexwait }) => {
  const web3 = useWeb3();
  var currencyFormatter = require("currency-formatter");
  const [MyDopBalance, setMyDopBalance] = useState("");
  const [Amountclamiednow, setAmountclamiednow] = useState();
  const [Amountclamiednowss, setAmountclamiednowss] = useState();
  console.log("here is", Amountclamiednowss)
  const [Amountclamiednows, setAmountclamiednows] = useState();
  const [totalamount, settotalamount] = useState();
  const [getpooldata, setgetpooldata] = useState();

  // console.log("totalamount",totalamount)




// console.log("value", stakeamountlimit , aggregateTotalStaked)
  const { GetBal } = GetBalance();
  const [balanceOf, setBalanceOf] = useState("");

  // console.log("samount styakers", Amountclamiednows)

  var rewardPerYear = 50000000;
  var apy = (( rewardPerYear * 12 )  / MyDopBalance) * 100

  // console.log("value get",apy)

  const DopBalanceCheck = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.totalStaked().call();
      // let balance = parseInt(approved) / 1000000000000000000
      // console.log("balance", approved);
      setMyDopBalance(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };

  const GetDopToken = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.rewardWallet().call();
      setgetpooldata(approved);
      // let balance = parseInt(approved) / 1000000000000000000
      // console.log("balance", approved);
      // setMyDopBalance(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };

  const GetDopTokenstakedpercent = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.rewardPerToken().call();
      setAmountclamiednowss(approved  / 10 ** 18);
      // let balance = parseInt(approved) / 1000000000000000000
      // console.log("balance", approved);
      // setMyDopBalance(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };


  

 

  const getBalance = async (getpooldata) => {
    let result = await GetBal(getpooldata);
    // let result2 = await GetHolder()
    // console.log("resulttvl", result2)
    setBalanceOf(result);
  };

  // console.log("statusss", balanceOf)


  const getClaimeduserdata = () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                    aggregates(where: {id: "Total Stakers"}) {
                      amount
                    }
                  }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("total stakers", response)
        setAmountclamiednows(response?.data?.data?.aggregates[0]?.amount);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getClaimeduserdatas = () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                    aggregates(where: {id: "Total Rewards Claimed"}) {
                      amount
                    }
                  }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("ammount total clamied", response);
        setAmountclamiednow(response?.data?.data?.aggregates[0]?.amount);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    // console.log("error get");
    // console.log("out", indexwaits)

    // console.log("in")
    getClaimeduserdatas();
    getClaimeduserdata();
    DopBalanceCheck();
    GetDopToken();
    StakeAmountLimit();
    StakeAggregate();
    GetDopTokenstakedpercent();
  }, []);



  useEffect(() => {
    // console.log("error get");
    // console.log("out", indexwaits)
    var totalvalue = aggregateTotalStaked / stakeamountlimit * 100
    settotalamount(totalvalue)

  }, [aggregateTotalStaked, stakeamountlimit]);

  useEffect(() => {
    // console.log("error get");
    // console.log("out", indexwaits)
    if (getpooldata) {
      // console.log("in")
      getBalance(getpooldata);
    }
  }, [getpooldata]);

  return (
    <>
      <section className="statsmain">
        <div className="custom-container paddmobilezero p-0">
          {/* <h5 className="statshead">GEMS Staking Stats</h5> */}
          <div className="statscardtwo">
            <div className="statcard">
              {/* <img src="\assets\cardonemainimg.svg" alt="statcardimg" className="statcardmainimg" /> */}

              <img
                src="\assets\cardfour.svg"
                alt="statcardimg"
                className="statcardimg"
              />
              <div className="parentsssss">
                <p className="statcardpara">Total Rewards Earned</p>
                <h6 className="statcardhead">
                  {Amountclamiednow ? currencyFormatter
                    .format(Amountclamiednow, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100) : 0} DOP
                </h6>
              </div>
            </div>
            <div className="statcard">
              {/* <img src="\assets\cardtwomainimg.svg" alt="statcardimg" className="statcardmainimg" /> */}
              <img
                src="\assets\cardone.svg"
                alt="statcardimg"
                className="statcardimg"
              />
              <div className="parentsssss">
                <p className="statcardpara">APY</p>
                <h6 className="statcardhead"> {apy ? parseFloat(apy).toFixed(2) : 0} %</h6>
              </div>
            </div>
            <div className="statcard">
              <img
                src="\assets\cardtwo.svg"
                alt="statcardimg"
                className="statcardimg"
              />
              <div className="parentsssss">
                <p className="statcardpara">$DOP Staking Max Cap</p>
                <div className="progress-barrrr">
                  {/* <ProgressBar now={totalamount} /> */}
                  <h6 className="statcardhead">{aggregateTotalStaked ? currencyFormatter
                    .format(aggregateTotalStaked, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100) : 0} DOP <span className="offcoloreher"> of <br></br> </span> 
                    <span className="colorfoffff">
                    {currencyFormatter
                    .format(stakeamountlimit, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100) ?  currencyFormatter
                    .format(stakeamountlimit, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100) : 0} DOP
                      </span> </h6>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stats;
