import React, { useState } from 'react'
import "./terms.scss"
import Navbar from '../landing/header/Navbar'
import Footer from '../landing/footer/Footer'

const Terms = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Navbar handleShow={handleShow} show={show} setShow={setShow} />
            <section className="termofuse_section">
                <img src="\assets\termofuse-bg.png" alt="img" className='img-fluid termofuse-bg' />
                <div className="custom-container">
                    <div className="main-heading">
                        <h6>DOP Token Staking </h6>
                        <h4>TERMS OF SERVICE</h4>
                    </div>
                    <div className="bottom-content">
                        <p><span>Binding Effect.</span> By participating in the DOP token staking program, you agree to be bound by these terms and conditions and assume all risks associated with staking your DOP tokens.</p>
                        <p><span>Staking and Rewards.</span> By staking your DOP tokens on our staking platform, available at Gems launchpad platform (the “Platform”), you will begin accruing rewards immediately. The rewards are calculated based on the current Annual Percentage Yield (APY), which is determined by the total amount of DOP tokens staked in the system. During the staking period, your tokens will be held on a smart contract and will not be available for any use by you (including transfer, swap, payment as fees or any other use) until unstaked and returned to your wallet. We specifically note that by staking your DOP tokens you will be exposed to fluctuations of the DOP token price compared to other tokens, cryptographic coins or fiat currencies during the staking term (including the lockage period set forth in below). Please note that the staking and the unstaking are subject to payment of certain third-party fees such as network or gas fees, as charged by your wallet or the applicable blockchain network. Please note that the DOP Staking Program will allow the staking of up to 1,000,000,000 DOP tokens. Staking your token will only be available for a period of seven days commencing on July 2, 2024. The DOP token staking program on the Platform only provide rewards for a period of 30 days commencing on July 2, 2024, and staking of DOP tokens following the expiration of the rewarding period shall not entitle the staking user for any reward.</p>
                        <p><span>Unstaking.</span> You may unstake your DOP tokens at any time. However, please note that unstaked tokens will be subject to a mandatory 7-day waiting period before they are released to your wallet. During this 7-day period, your unstaked tokens will not earn any rewards.</p>
                        <p><span>Reward Claims.</span> Staking rewards can only be claimed after a 90-day lockup period, which starts from the initial staking date. This means that you must wait 90 days from the date you first staked your tokens before you can claim any rewards earned during that period.</p>
                        <p><span>Reward Restaking.</span> If you choose to restake your earned rewards, they will be subject to a new 90-day lockup period. By restaking rewards, you can compound your earnings over time.</p>
                        <p><span>APY Fluctuations.</span> The APY for staking DOP tokens is not fixed and can change at any time. The APY depends on the total amount of DOP tokens staked in the system. As more tokens are staked, the APY may decrease. Conversely, if fewer tokens are staked, the APY may increase. For example, if the current APY is 200% and the total amount of staked tokens doubles, the APY may drop to 100%. The APY applicable in any given moment is stated in the Platform.</p>
                        <p><span>No Guarantee of Rewards.</span> While we strive to provide accurate estimates of staking rewards based on the current APY, we cannot guarantee any specific reward amount. Actual rewards may vary based on various factors, including fluctuations in the total amount of staked tokens and any changes to the staking program. </p>
                        <p><span>Risk of Loss.</span> By staking your DOP tokens, you acknowledge and accept the risk of potential loss due to unforeseen circumstances such as hacks, smart contract vulnerabilities, or other technical issues. We will implement reasonable security measures to protect staked tokens, but we cannot guarantee absolute security. In the event of a hack or other loss of staked tokens, we will not be held responsible or liable for any resulting damages or losses; furthermore, you acknowledge that the trading price of DOP tokens in the open market may be reduced at the staking period and/or the waiting period, on which periods your staked DOP tokens (including the rewards) cannot be transferred or sold, and you accept the risk of such reduction of value.</p>
                        <p><span>User Responsibility.</span> . It is your responsibility to understand the terms and conditions of the DOP token staking program before participating. By staking your tokens, you acknowledge that you have read, understood, and agreed to these terms and conditions, including the risk of loss.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Terms
